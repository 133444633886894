// Button styles
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

// Base Styles
.button
	+flex-row-center
	padding: 12px 20px
	height: 48px
	border-radius: 8px
	border: 2px solid $Neutral00
	&:hover
		cursor: pointer

// Button Typography
.typography1
	+button1
	color: $Neutral00

.typography2
	+button2
	color: $Neutral00

.typography3
	+button3
	color: $Neutral00

// Button Types
.primary
	background: $PrimaryYellow
	border-color: $PrimaryYellow
	color: $Neutral08
	&:hover
		filter: brightness(0.9)
	&.disabled
		background: rgba(252, 252, 252, 0.5) !important
		border: 2px solid rgba(239, 239, 239, 0.5) !important
		color: rgba(26, 29, 31, 0.75) !important
		&:hover
			filter: brightness(1) !important

.outline
	border-color: $Neutral07
	color: $Neutral07
	&:hover
		background: transparentize($Neutral08, .95)
		border-color: $Neutral08
		color: $Neutral08
	&.disabled
		background: transparentize($Neutral04, .8)
		border-color: $Neutral04
		color: $Neutral04

.light
	background: $Neutral01
	border-color: $Neutral03
	color: $Neutral07
	&:hover
		border-color: $Neutral04

.dark
	background: $Neutral07
	border-color: $Neutral06
	color: $Neutral01
	&:hover
		filter: brightness(0.9)

.red
	background: $SecondaryRed
	border-color: $SecondaryRed
	color: $Neutral08
	&:hover
		filter: brightness(0.9)

.layer
	background: $Neutral08
	border: 1px solid #000000
	border-radius: 8px
	position: relative
	box-sizing: border-box
	z-index: 2
	box-shadow: $Neutral00 5px 6px, $Neutral08 5px 6px 0px 1px
	margin-bottom: spacing(1)
	&:hover
		filter: brightness(0.9)
	&:active
		box-shadow: none
		transform: translate(8px,8px)
		transition: all 0.1s linear

.light,.dark,.red
	&.disabled
		background: $Neutral01 !important
		border: 2px solid $Neutral03 !important
		color: $Neutral07 !important
		&:hover
			filter: brightness(1) !important

.fullWidth
	width: 100%

.icon
	width: 24px
	height: 24px
	margin-right: 8px

.storybook
	+flex-column-center
	width: 100%
	min-height: 90vh
	background: $SecondaryBlue
