// AuthView styles
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

.content
    +center-element
    +flex-column
    padding: 32px
    padding-top: $HeaderHeight
    margin-left: auto
    margin-right: auto
    width: stretch
    max-width: 504px
    +md
        padding-top: $HeaderHeight - 36px

.video
    margin-bottom: 40px
    min-height: 200px

.title
    margin-bottom: 12px
    +h4
    color: $Neutral00
    +md
        +h1
        margin-bottom: 20px

.subtitle
    +body1-medium
    color: $Neutral00
    +md
        +title2-medium
        margin-bottom: 20px

.authContainer
    +md
        max-width: 320px
