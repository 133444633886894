// Loader styles
// Loader 2 from https://codepen.io/nobuakihonma/pen/dYbqLQ
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

// Loader Styles
=loader($size: 56px)
    display: block
    height: $size
    width: $size
    -webkit-animation: loader-1 3s linear infinite
    animation: loader-1 3s linear infinite
    span
        display: block
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        margin: auto
        height: $size
        width: $size
        clip: rect(calc($size / 2), $size, $size, 0)
        -webkit-animation: loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite
        animation: loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite

        &::before
            content: ""
            display: block
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            margin: auto
            height: $size
            width: $size
            border: ($size * 0.1) solid transparent
            border-top: ($size * 0.1) solid $PrimaryYellow
            border-radius: 50%
            -webkit-animation: loader-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite
            animation: loader-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite

        &::after
            content: ""
            display: block
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            margin: auto
            height: $size
            width: $size
            border: ($size * 0.1) solid $PrimaryYellow
            opacity: 0.7
            border-radius: 50%

@-webkit-keyframes loader-1
  0%
    -webkit-transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)

@keyframes loader-1
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)

@-webkit-keyframes loader-2
  0%
    -webkit-transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)

@keyframes loader-2
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)

@-webkit-keyframes loader-3
  0%
    -webkit-transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)

@keyframes loader-3
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)

// Loader Size Classes

.loader24
    +loader(24px)
.loader32
    +loader(32px)
.loader40
    +loader(40px)
.loader48
    +loader(48px)
.loader56
    +loader(56px)
.loader64
    +loader(64px)
