// Tooltip Styles
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

$TipSpacing: 12px

.tooltip
    position: relative
    &:hover
        cursor: help
        span
            display: flex
            flex-direction: column
            justify-content: center

.content
    position: absolute
    +caption1
    color: $Neutral02
    display: none
    background: $Neutral06
    padding: 8px 12px
    border-radius: 4px
    b
        +caption2
    p
        +caption2-medium

.top
    top: 0
    transform: translate(-50%,calc(-100% - 12px))
    left: 50%

.left
    left: 0
    top: 50%
    transform: translate(calc(-100% - $TipSpacing),50%)

.bottom
    bottom: 0
    left: 50%
    transform: translate(-50%,calc(100% + $TipSpacing))

.right
    right: 0
    top: 50%
    transform: translate(calc(100% + $TipSpacing), -50%)

// Useful fiddle -> https://jsfiddle.net/8nnwytbL/
