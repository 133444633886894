// Auth components styles
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

.loadingContainer
    +flex-column-center
    width: 100%
    min-height: 140px

.authContainer
    +flex-column

.oAuthSignUp
    +body1-medium
    color: $Neutral00
    margin-top: 8px

.hr
    margin: spacing(3) 0

.googleButton
    margin: spacing(2) 0

.emailField
    margin-top: 20px

.signInLink
    +body1-medium
    color: $Neutral00
    text-align: center
    text-decoration-line: underline
    margin-top: 36px
    margin-bottom: 36px
    &:hover
        cursor: pointer
    +md
        max-width: 320px

.emailPrompt
    +body1-medium
    color: $Neutral00

.tos
    +body1-medium
    color: $Neutral00
    margin-top: spacing(2)
    a
        color: $Neutral00
        text-decoration-line: underline
    &:hover
        cursor: pointer