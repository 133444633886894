// RegistrationLayout styles
@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module"

$SideMenuWidth: 400px

.layoutRoot
    +flex-column-center
    max-width: 100%
    +md
        +flex-row

.sideMenuRoot
    +flex-column
    background: $Neutral08
    height: 100vh
    width: $SideMenuWidth
    position: fixed
    top: 0
    left: 0
    overflow-x: hidden
    padding-top: 100px

.layoutContent
    +flex-column
    max-width: 100%
    width: 100%
    margin: 0px
    padding: 0px
    +md
        margin-left: $SideMenuWidth

.backdrop
    height: 100%
    width: 100%
    background: linear-gradient(197.7deg, #16243F 10.95%, #000000 42.32%, #1A150B 52.75%, #2F2719 76.72%)
    filter: blur(100px)
    backdrop-filter: blur(250px)
    transform: matrix(-1, 0, 0, 1, 0, 0)

.menuContent
    position: absolute
    +flex-column
    justify-content: space-around
    padding: 32px
    z-index: 10

.videoContainer
    margin-bottom: 88px

.videoCaption
    +caption1
    color: $Neutral03-5
    margin-top: 12px

.listContainer
    +flex-column

.listTitle
    color: $Neutral00
    margin-bottom: 30px

.listItem
    +flex-row
    align-items: center
    +base1-bold
    color: $Neutral00
    width: 100%
    margin-bottom: 16px

.icon
    fill: $PrimaryYellow
    margin-right: 12px
