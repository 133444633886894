@import "styles/variables.module"
@import "styles/typography.module"
@import "styles/mixins.module.sass"

$ErrorBorder: 2px solid $PrimaryRed

.width
    width: 100%

.gutterBottom
    margin-bottom: 24px

.label
    +base2
    margin-bottom: 12px
    +flex-row
    &.dark
        color: $Neutral00
    &.light
        color: $Neutral05

.tooltip
    top: -1px
    margin-left: 4px
    svg
        fill: $Neutral04

.wrap
    position: relative

$InputHeight: 48px

.input
    width: 100%
    height: $InputHeight
    padding: 0 14px
    border-radius: 12px
    +base1-semibold
    &.dark
        color: $Neutral02
        background: $Neutral08
        +placeholder
            color: $Neutral05
    &.light
        color: $Neutral07
        background: $Neutral02
        +placeholder
            color: $Neutral04
    &:focus
        // border-color: $n4

.startIconContainer
    .input
        padding-left: 48px

.startIcon
    fill: $Neutral04
    position: absolute
    top: 12px
    left: 12px

.adornment
    .input
        padding-left: 56px

.startAdornment
    +flex-column-center
    position: absolute
    height: $InputHeight
    width: 48px
    +base1-semibold
    color: $Shade04-50
    border: none
    border-radius: 12px 0 0 12px
    &.dark
        background: $Neutral06
    &.light
        background: $Neutral03

.error
    .input
        border: $ErrorBorder
        background: rgba(255, 188, 153, 0.1)
        color: $PrimaryRed
        +placeholder
            color: $Shade04-40
    .startAdornment
        border-left: $ErrorBorder
        border-top: $ErrorBorder
        border-bottom: $ErrorBorder
    .validationIcon
        fill: $PrimaryRed
    .validationContainer
        &:hover
            cursor: help

.validationContainer
    position: absolute
    top: 12px
    right: 12px

.validationIcon
    fill: $PrimaryGreen

.errorText
    +caption2-medium
    color: $PrimaryRed
    position: absolute
    top: $InputHeight + 8px
    left: 4px
