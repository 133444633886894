@import "styles/variables.module.sass"
@import "styles/mixins.module.sass"
@import "styles/typography.module.sass"

.header
    +flex-row-center
    position: fixed
    width: 100%
    max-width: 100%
    height: $HeaderHeight
    z-index: $HeaderZIndex
    background: $Neutral07
    img
        max-width: 164px
    +md
        background: transparent
        justify-content: space-between
        padding: spacing(3)
        left: spacing(2)

.signOut
    +caption2
    color: $Shade04-75
    right: spacing(2)
    position: fixed
    &:hover
        cursor: pointer
        text-decoration: underline
    span
        +caption1
        color: $Neutral01
